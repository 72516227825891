
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EStatusRetornoBancario } from '@/models/Enumeradores/Financeiro/EStatusRetornoBancario';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IDTOCartaCorrecao } from '@/models/DTO/Fiscal/IDTOCartaCorrecao';
import { INotaFiscal, INotaFiscalDestinatario } from '@/models/Entidades/Fiscal/INotaFiscal';
import ServicoFaturamento from '@/servicos/Fiscal/ServicoFaturamento';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';

export default defineComponent({
  name: 'CartaCorrecaoModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    venda: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    PreviewPdf,
    MensagemSemDados,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const { modalBase, apresentarBarraProgresso, ocultarBarraProgresso } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoVendas = new ServicoVendas();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    const servicoFaturamento = new ServicoFaturamento();
    servicoVendas.requisicaoSistema();
    servicoNotaFiscal.requisicaoSistema();
    servicoFaturamento.requisicaoSistema();

    const state = reactive({
      cartas: [] as IDTOCartaCorrecao[],
      carta: {} as IDTOCartaCorrecao,
      notaFiscal: {} as INotaFiscal,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Carta', dataIndex: 'carta', key: 'Carta', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Emissão', dataIndex: 'dataEmissao', key: 'DataEmissao', position: 2, visible: true, width: 150, align: 'center',
          },
          {
            title: 'Correção', dataIndex: 'correcao', key: 'Correcao', position: 2, visible: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 3, visible: true, width: 70, align: 'center',
          },
        ];
      }
    }

    async function limparTela() {
      state.cartas = [] as IDTOCartaCorrecao[];
      state.carta = {} as IDTOCartaCorrecao;
      state.notaFiscal = {} as INotaFiscal;
      state.notaFiscal.destinatario = {} as INotaFiscalDestinatario;
      state.arquivosPdf = [] as IArquivoPdf[];
      gradeBase.codigosSelecionados = [] as number[];
      preencherColunas();
    }

    async function obterNotaFiscal() {
      const venda = await servicoVendas.obter(props.venda);
      state.notaFiscal = await servicoNotaFiscal.obter(venda.codigoNotaFiscal, true);
      state.cartas = await servicoFaturamento.obterCartasCorrecao(props.empresa, state.notaFiscal.chaveAcesso);
    }

    async function obterCartas() {
      state.cartas = await servicoFaturamento.obterCartasCorrecao(props.empresa, state.notaFiscal.chaveAcesso);
    }

    async function emitirCCe() {
      if (state.carta.correcao === undefined || state.carta.correcao === '') {
        apresentarMensagemAlerta('A carta de correção está vazia!');
        return;
      }

      state.carta.empresa = props.empresa;
      state.carta.chaveAcesso = state.notaFiscal.chaveAcesso;
      apresentarBarraProgresso();
      const retorno = await servicoFaturamento.emitirCartaCorrecao(state.carta);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso('Carta emitida com sucesso!');
        state.arquivosPdf = [] as IArquivoPdf[];
        const arquivoPdf = {} as IArquivoPdf;
        arquivoPdf.nome = 'Carta de Correção';
        arquivoPdf.link = retorno.linkArquivo;
        state.carta.correcao = '';
        state.arquivosPdf.push(arquivoPdf);
        obterCartas();
      } else {
        apresentarMensagemErro('Ocorreu um erro ao tentar emitir a carta de correção!');
      }
      ocultarBarraProgresso();
    }

    function imprimirCCe(index: number) {
      state.arquivosPdf = [] as IArquivoPdf[];
      const arquivoPdf = {} as IArquivoPdf;
      arquivoPdf.nome = 'Carta de Correção';
      arquivoPdf.link = state.cartas[index].linkArquivo;
      state.arquivosPdf.push(arquivoPdf);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        await obterNotaFiscal();
        await obterCartas();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      window,
      EStatusRetornoBancario,
      EStatusTituloFinanceiro,
      UtilitarioData,
      UtilitarioMascara,
      UtilitarioGeral,
      ECustomRenderRow,
      emitirCCe,
      imprimirCCe,
    };
  },
});
