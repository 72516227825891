
import {
  ComponentPublicInstance,
  computed, defineComponent, reactive, ref,
} from 'vue';
import ProdutoModal from '@/views/Cadastros/Produtos/ProdutoModal.vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { IDTOProdutoDefinicaoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicaoMovimento';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IProduto } from '@/models/Entidades/Cadastros/Produtos/IProduto';

export default defineComponent({
  name: 'SelecionarProdutoMovimento',
  props: {
    codigoSelecionado: {
      type: Number || undefined,
    },
    descricao: {
      type: String,
      default: '',
    },
    codigoUnidadePrincipal: {
      type: Number,
      default: 0,
    },
    fatorUnidadePrincipal: {
      type: Number,
      default: 1,
    },
    codigoInterno: {
      type: String,
      default: '',
    },
    codigoBarras: {
      type: String,
      default: '',
    },
    origemMercadoria: {
      type: Number,
      default: 0,
    },
    codigoNcm: {
      type: Number,
      default: 0,
    },
    ncm: {
      type: String,
      default: '',
    },
    siglaUnidade: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    apresentarCadastro: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    filtrarVinculoEstoqueEntrada: {
      type: Boolean,
      default: false,
    },
    filtrarVinculoEstoqueSaida: {
      type: Boolean,
      default: false,
    },
    buscarDadosAtualizados: {
      type: Boolean,
      default: false,
    },
    emDigitacao: {
      type: Boolean,
      default: false,
    },
    produtoPreCadastro: {
      type: Object as () => IProduto,
    },
  },
  components: {
    ProdutoModal,
  },
  emits: ['update:codigoSelecionado', 'update:emDigitacao', 'update:descricao', 'update:codigoUnidadePrincipal', 'update:fatorUnidadePrincipal', 'update:codigoInterno', 'update:codigoBarras', 'update:origemMercadoria', 'update:codigoNcm', 'update:ncm', 'update:siglaUnidade', 'update:buscarDadosAtualizados', 'change', 'blur', 'keyDownAbaixo', 'keyDownAcima'],
  setup(props, { emit }) {
    const {
      selecionarBase,
      instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    const refSelecionarProduto = ref<ComponentPublicInstance | null>(null);
    const focoCampoProduto = ref<boolean>(false);
    let debounce = 0;
    let debounceEnter = 0;
    let tempoUltimaDigitacao = 0;
    const tempoPadraoLeitorCodigoBarras = 50; // Tempo padrão em milissegundos para considerar como entrada de leitor de código de barras
    const servicoProduto = new ServicoProduto();

    const state = reactive({
      listaDefinicoes: [] as IDTOProdutoDefinicaoMovimento[],
    });

    const computedEmDigitacao = computed({
      get: () => props.emDigitacao,
      set: (val: boolean) => {
        emit('update:emDigitacao', val);
      },
    });

    const computedDescricao = computed({
      get: () => props.descricao,
      set: (val: string) => {
        emit('update:descricao', val);
      },
    });

    const computedCodigoUnidadePrincipal = computed({
      get: () => props.codigoUnidadePrincipal,
      set: (val: number) => {
        emit('update:codigoUnidadePrincipal', val);
      },
    });

    const computedFatorUnidadePrincipal = computed({
      get: () => props.fatorUnidadePrincipal,
      set: (val: number) => {
        emit('update:fatorUnidadePrincipal', val);
      },
    });

    const computedCodigoInterno = computed({
      get: () => props.codigoInterno,
      set: (val: string) => {
        emit('update:codigoInterno', val);
      },
    });

    const computedCodigoBarras = computed({
      get: () => props.descricao,
      set: (val: string) => {
        emit('update:codigoBarras', val);
      },
    });

    const computedOrigemMercadoria = computed({
      get: () => props.origemMercadoria,
      set: (val: number) => {
        emit('update:origemMercadoria', val);
      },
    });

    const computedCodigoNcm = computed({
      get: () => props.codigoNcm,
      set: (val: number) => {
        emit('update:codigoNcm', val);
      },
    });

    const computedNcm = computed({
      get: () => props.ncm,
      set: (val: string) => {
        emit('update:ncm', val);
      },
    });

    const computedBuscarDadosAtualizados = computed({
      get: () => props.buscarDadosAtualizados,
      set: (val: boolean) => {
        emit('update:buscarDadosAtualizados', val);
      },
    });

    const computedSiglaUnidade = computed({
      get: () => props.siglaUnidade,
      set: (val: string) => {
        emit('update:siglaUnidade', val);
      },
    });

    function verificacaoPreBusca(valor: any):boolean {
      if (selecionarBase.emDigitacao) { return false; }
      if (!UtilitarioGeral.valorValido(valor)) { return false; }
      if (!(valor > 0)) { return false; }

      if (props.codigoSelecionado === 0) {
        return false;
      }
      // Verifica se já tem uma lista de opções e tenta procurar o valor selecionado nela pra evitar consumo no servidor
      if (UtilitarioGeral.validaLista(state.listaDefinicoes)) {
        const existeLista = state.listaDefinicoes.find((c) => c.codigo === props.codigoSelecionado);
        if (existeLista !== undefined) {
          return false;
        }
      }

      return true;
    }

    function verificacaoUltimaPesquisa(valor: any):boolean {
      if (valor === selecionarBase.valorUltimaPesquisa) {
        if (UtilitarioGeral.validaLista(state.listaDefinicoes) && state.listaDefinicoes.length > 1) { return false; }
      }

      return true;
    }

    function aguardarConclusaoCarregamento(computedCodigoSelecionado: number):boolean {
      return (!selecionarBase.emDigitacao && !UtilitarioGeral.validaLista(state.listaDefinicoes) && (UtilitarioGeral.validaCodigo(computedCodigoSelecionado)));
    }

    function comportamentoPadraoSemResultado(): void{
      if (!UtilitarioGeral.validaLista(state.listaDefinicoes)) {
        if (UtilitarioGeral.validaCodigo(props.codigoSelecionado)) {
          emit('update:codigoSelecionado', 0);
        }
      }
    }

    function change(codigoSelecionado: number) {
      computedEmDigitacao.value = false;
      emit('change', codigoSelecionado);
    }

    function adicionarDefinicaoPorPropriedades(codigo:number) {
      const definicao: IDTOProdutoDefinicaoMovimento = {} as IDTOProdutoDefinicaoMovimento;
      definicao.codigo = codigo;
      definicao.codigoInterno = props.codigoInterno;
      definicao.codigoBarras = props.codigoBarras;
      definicao.produto = props.descricao;
      definicao.codigoUnidadePrincipal = props.codigoUnidadePrincipal;
      definicao.fatorUnidadePrincipal = props.fatorUnidadePrincipal;
      definicao.origemMercadoria = props.origemMercadoria;
      definicao.codigoNcm = props.codigoNcm;
      definicao.ncm = props.ncm;
      definicao.siglaUnidade = props.siglaUnidade;
      state.listaDefinicoes.push(definicao);
    }

    function adicionarDefinicaoCadastro() {
      const definicao: IDTOProdutoDefinicaoMovimento = {} as IDTOProdutoDefinicaoMovimento;
      definicao.codigo = 0;
      definicao.produto = 'Cadastrar';
      state.listaDefinicoes.push(definicao);
    }

    async function obterProdutoPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      state.listaDefinicoes = [];
      if (!props.buscarDadosAtualizados && valor > 0) {
        adicionarDefinicaoPorPropriedades(valor);
      } else {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
        state.listaDefinicoes = await servicoProduto.consultaRapidaDefinicoesMovimentos(parametrosConsultaRapida, true);
        if (UtilitarioGeral.validaLista(state.listaDefinicoes)) {
          const definicaoProduto = state.listaDefinicoes.find((c) => c.codigo === valor);
          if (definicaoProduto !== undefined && definicaoProduto != null) {
            computedDescricao.value = definicaoProduto.produto;
            computedCodigoUnidadePrincipal.value = definicaoProduto.codigoUnidadePrincipal;
            computedFatorUnidadePrincipal.value = definicaoProduto.fatorUnidadePrincipal;
            computedCodigoInterno.value = definicaoProduto.codigoInterno;
            computedCodigoBarras.value = definicaoProduto.codigoBarras;
            computedOrigemMercadoria.value = definicaoProduto.origemMercadoria;
            computedCodigoNcm.value = definicaoProduto.codigoNcm;
            computedNcm.value = definicaoProduto.ncm;
            computedSiglaUnidade.value = definicaoProduto.siglaUnidade;
            computedBuscarDadosAtualizados.value = false;
          }
        }
        if (props.apresentarCadastro) {
          adicionarDefinicaoCadastro();
        }
        comportamentoPadraoSemResultado();
      }
    }

    function obterProduto():any {
      if (props.codigoSelecionado === 0) {
        return undefined;
      }
      obterProdutoPorCodigo(props.codigoSelecionado);
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterProduto(),
      set: (val: number) => {
        if (!props.buscarDadosAtualizados && !UtilitarioGeral.validaLista(state.listaDefinicoes)) {
          state.listaDefinicoes = [];
          adicionarDefinicaoPorPropriedades(val);
        } else {
          const definicaoProduto = state.listaDefinicoes.find((c) => c.codigo === val);
          if (definicaoProduto !== undefined && definicaoProduto != null) {
            computedDescricao.value = definicaoProduto.produto;
            computedCodigoUnidadePrincipal.value = definicaoProduto.codigoUnidadePrincipal;
            computedFatorUnidadePrincipal.value = definicaoProduto.fatorUnidadePrincipal;
            computedCodigoInterno.value = definicaoProduto.codigoInterno;
            computedCodigoBarras.value = definicaoProduto.codigoBarras;
            computedOrigemMercadoria.value = definicaoProduto.origemMercadoria;
            computedCodigoNcm.value = definicaoProduto.codigoNcm;
            computedNcm.value = definicaoProduto.ncm;
            computedSiglaUnidade.value = definicaoProduto.siglaUnidade;
          }
        }

        emit('update:codigoSelecionado', val);
      },
    });

    function blur() {
      selecionarBase.emDigitacao = false;
      computedEmDigitacao.value = false;
      focoCampoProduto.value = false;
      emit('blur');
    }

    function dispararEnter() {
      clearTimeout(debounceEnter);
      debounceEnter = setTimeout(async () => {
        if (refSelecionarProduto.value) {
          const enterEvent = new KeyboardEvent('keydown', {
            key: 'Enter',
            code: 'Enter',
            bubbles: true,
            cancelable: true,
          });
          refSelecionarProduto.value.$el.dispatchEvent(enterEvent);
        }
      }, 300);
    }
    async function pesquisarProduto(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;

      const horaAtual = new Date().getTime();
      const tempoDiferencaDigitacao = horaAtual - tempoUltimaDigitacao;
      let utilizouLeitorCodigoBarras = false;

      if (tempoDiferencaDigitacao !== 0 && (tempoDiferencaDigitacao < tempoPadraoLeitorCodigoBarras)) {
        utilizouLeitorCodigoBarras = true;
      }

      tempoUltimaDigitacao = horaAtual;
      selecionarBase.buscandoDados = true;
      state.listaDefinicoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        state.listaDefinicoes = await servicoProduto.consultaRapidaDefinicoesMovimentos(parametrosConsultaRapida, true, props.filtrarVinculoEstoqueEntrada, props.filtrarVinculoEstoqueSaida);
        if (props.apresentarCadastro) {
          adicionarDefinicaoCadastro();
        }
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        computedEmDigitacao.value = true;
        selecionarBase.buscandoDados = false;

        if (utilizouLeitorCodigoBarras) {
          if (UtilitarioGeral.validaLista(state.listaDefinicoes)) {
            if (state.listaDefinicoes.length === 1) {
              computedCodigoSelecionado.value = state.listaDefinicoes[0].codigo;
              dispararEnter();
            }
          }
        }
      }, 600);
    }

    function cadastrar() {
      if (props.apresentarCadastro) {
        computedCodigoSelecionado.value = 0;
        selecionarBase.operacaoCadastro.tipoPermissaoDados = EPermissaoDados.Incluir;
        selecionarBase.operacaoCadastro.listaPermissoesDados = [];
        selecionarBase.operacaoCadastro.codigoRegistro = 0;
        selecionarBase.operacaoCadastro.empresaSelecionada = props.empresas[0];
        selecionarBase.operacaoCadastro.codigoRegistroDuplicar = 0;
        selecionarBase.operacaoCadastro.codigoRegistroPai = 0;
        selecionarBase.operacaoCadastro.requisicaoSistema = true;
        selecionarBase.apresentarCadastro = true;
      }
    }

    async function cadastroConcluido(codigo:number) {
      const codigoDefinicao = await servicoProduto.obterCodigoDefinicaoPrincipal(codigo);
      computedBuscarDadosAtualizados.value = true;
      await obterProdutoPorCodigo(codigoDefinicao);
      computedCodigoSelecionado.value = codigoDefinicao;
      change(codigoDefinicao);
    }

    function focus() {
      if (!UtilitarioGeral.validaCodigo(computedCodigoSelecionado.value)) {
        computedEmDigitacao.value = true;
        pesquisarProduto('');
      }

      focoCampoProduto.value = true;
    }

    return {
      props,
      state,
      selecionarBase,
      refSelecionarProduto,
      aguardarConclusaoCarregamento,
      pesquisarProduto,
      computedCodigoSelecionado,
      computedEmDigitacao,
      focoCampoProduto,
      change,
      focus,
      blur,
      cadastrar,
      cadastroConcluido,
      computedBuscarDadosAtualizados,
    };
  },
});
