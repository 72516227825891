
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarUnidade from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidade.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCfop from '@/components/Cadastros/Tributacoes/Cfops/SelecionarCfop.vue';
import NotaFiscalItemDetalhe from '@/components/Fiscal/NotasFiscais/NotaFiscalItemDetalhe.vue';
import NotaFiscalItemImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalItemImpostos.vue';
import NotaFiscalItemEstoques from '@/components/Fiscal/NotasFiscais/NotaFiscalItemEstoques.vue';
import NotaFiscalItemDadosEspecificos from '@/components/Fiscal/NotasFiscais/NotaFiscalItemDadosEspecificos.vue';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IDevolucaoVendaItem } from '@/models/Entidades/Vendas/IDevolucaoVenda';
import SelecionarCenarioFiscal from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCenarioFiscal.vue';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { IVendaItem } from '@/models/Entidades/Vendas/IVenda';
import { INotaFiscalItemEstoque } from '@/models/Entidades/Fiscal/INotaFiscal';

export default defineComponent({
  name: 'DevolucaoVendaItemModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    estoquesDisponiveis: {
      type: Array as () => IEstoque[],
      required: true,
    },
    item: {
      type: Object as () => IDevolucaoVendaItem,
      required: true,
    },
    totalNota: {
      type: Number,
      required: true,
    },
    existeProximo: {
      type: Boolean,
      required: true,
    },
    existeAnterior: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarProduto,
    SelecionarSimNao,
    SelecionarUnidade,
    CampoNumerico,
    SelecionarCfop,
    NotaFiscalItemDetalhe,
    NotaFiscalItemImpostos,
    NotaFiscalItemEstoques,
    NotaFiscalItemDadosEspecificos,
    SelecionarCenarioFiscal,
  },
  emits: ['update:visivel', 'salvar', 'proximoItem', 'itemAnterior'],
  setup(props, { emit }) {
    const servicoVendas = new ServicoVendas();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    const servicoProduto = new ServicoProduto();
    servicoNotaFiscal.requisicaoSistema();
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      item: {} as IDevolucaoVendaItem,
      itemVenda: {} as IVendaItem,
      tabelasPreco: [] as number[],
      totalNota: 0,
      telaMobile: false,
    });

    function atualizarTotalItem() {
      state.totalNota = props.totalNota;
      state.item.itemNotaFiscal.valorTotal = state.item.itemNotaFiscal.quantidade * (state.item.itemNotaFiscal.precoUnitario - state.item.itemNotaFiscal.valorDesconto);
      if (props.item.itemNotaFiscal.compoeTotal && state.item.codigo !== undefined) {
        state.totalNota -= props.item.itemNotaFiscal.valorTotal;
      }

      if (state.item.itemNotaFiscal.compoeTotal) {
        state.totalNota += state.item.itemNotaFiscal.valorTotal;
      }

      if (state.item.itemNotaFiscal.estoques.length === 1) {
        state.item.itemNotaFiscal.estoques[0].quantidade = state.item.itemNotaFiscal.quantidade;
      }
    }

    function atualizarQtdLancadEstoque(totalLancado: number) {
      state.item.itemNotaFiscal.quantidade = totalLancado;
    }

    function validarImpostos() {
      if (!UtilitarioGeral.valorValido(state.item.itemNotaFiscal.impostos.cst) && !UtilitarioGeral.valorValido(state.item.itemNotaFiscal.impostos.cson)) {
        apresentarMensagemAlerta('O CST/CSOSN deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.pis.cstPis === undefined || state.item.itemNotaFiscal.impostos.pis.cstPis === '') {
        apresentarMensagemAlerta('O CST do PIS deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.cofins.cstCofins === undefined || state.item.itemNotaFiscal.impostos.cofins.cstCofins === '') {
        apresentarMensagemAlerta('O CST do COFINS deve ser informado!');
        return false;
      }
      return true;
    }

    function validarEstoque() {
      if (!state.item.itemNotaFiscal.movimentarEstoque) return true;

      if (state.item.itemNotaFiscal.estoques.length === 0) {
        apresentarMensagemAlerta('Pelo menos um item de estoque deve ser informado!');
        return false;
      }

      let qtdTotal = 0;
      state.item.itemNotaFiscal.estoques.forEach((e) => {
        qtdTotal += e.quantidade;
      });

      if (state.item.itemNotaFiscal.quantidade !== qtdTotal) {
        apresentarMensagemAlerta('A quantidade total dos estoques está diferente da quantidade do item!');
        return false;
      }
      return true;
    }

    function validarCampos() {
      if (state.item.itemNotaFiscal.codigoProdutoDefinicao === undefined || state.item.itemNotaFiscal.codigoProdutoDefinicao === 0) {
        apresentarMensagemAlerta('O Produto deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.codigoUnidade === undefined || state.item.itemNotaFiscal.codigoUnidade === 0) {
        apresentarMensagemAlerta('A Unidade deve ser informada!');
        return false;
      }

      if (state.item.itemNotaFiscal.quantidade === undefined || state.item.itemNotaFiscal.quantidade < 1) {
        apresentarMensagemAlerta('A Quantidade deve ser informada!');
        return false;
      }

      if (state.item.itemNotaFiscal.quantidade > state.itemVenda.itemNotaFiscal.quantidade) {
        apresentarMensagemAlerta('A quantidade devolvida não pode ser maior que a quantidade do recebimento!');
        return false;
      }

      if (state.item.itemNotaFiscal.valorTotal === undefined || state.item.itemNotaFiscal.valorTotal < 1) {
        apresentarMensagemAlerta('O Total do Item deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.valorTotal === undefined || state.item.itemNotaFiscal.valorTotal < 1) {
        apresentarMensagemAlerta('O Total do Item deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.codigoCfop === undefined || state.item.itemNotaFiscal.impostos.codigoCfop === 0) {
        apresentarMensagemAlerta('O CFOP deve ser informado!');
        return false;
      }

      if (!validarImpostos()) {
        return false;
      }

      if (!validarEstoque()) {
        return false;
      }

      return true;
    }

    async function obterEstoquesDisponiveis() {
      const estoquesDisponiveis = await servicoProduto.PreencherEstoquesProduto(state.item.itemNotaFiscal.codigoProdutoDefinicao, props.empresa, props.estoquesDisponiveis);

      if (estoquesDisponiveis.length > 0) {
        state.item.itemNotaFiscal.estoquesDisponiveis = estoquesDisponiveis;
        state.item.itemNotaFiscal.codigosEstoquesDisponiveis = [] as number[];
        state.item.itemNotaFiscal.estoquesDisponiveis.forEach((e) => {
          state.item.itemNotaFiscal.codigosEstoquesDisponiveis.push(e.codigo);
        });
      }
    }

    function salvar() {
      if (validarCampos()) {
        if (state.item.codigo === undefined) {
          state.item.codigo = 0;
        } else {
          state.item.alterado = true;
        }
        emit('salvar', state.item);
      }
    }

    async function obterFilhos() {
      if (state.item.codigo > 0 && state.item.codigo !== undefined) {
        state.item.itemNotaFiscal.impostos = await servicoNotaFiscal.obterImpostoItem(state.item.codigoNotaFiscalItem);
        state.item.itemNotaFiscal.medicamento = await servicoNotaFiscal.obterDadosMedicamentoItem(state.item.codigoNotaFiscalItem);
        state.item.itemNotaFiscal.combustivel = await servicoNotaFiscal.obterDadosCombustivelItem(state.item.codigoNotaFiscalItem);
        state.item.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
        const estoques = await servicoNotaFiscal.obterEstoquesItem(state.item.codigoNotaFiscalItem);
        if (estoques.length > 0) {
          state.item.itemNotaFiscal.estoques = estoques;
        }
      }
    }

    async function obterDadosVenda() {
      state.itemVenda = await servicoVendas.obterItem(state.item.codigoVendaItem);
      obterEstoquesDisponiveis();
    }

    async function proximoItem() {
      emit('proximoItem');
      await obterFilhos();
    }

    async function itemAnterior() {
      emit('itemAnterior');
      await obterFilhos();
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.item = UtilitarioGeral.clonarObjeto(props.item);
        await obterDadosVenda();
        await obterFilhos();
      }
      telaBase.carregando = false;
    });

    watch(async () => props.item, async () => {
      state.item = props.item;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioGeral,
      UtilitarioMascara,
      salvar,
      proximoItem,
      itemAnterior,
      atualizarTotalItem,
      atualizarQtdLancadEstoque,
    };
  },
});
