import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ant-row",
  style: {"margin-bottom":"5px"}
}
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24 ss-alinhar-desktop-direita-mobile-centro" }
const _hoisted_3 = {
  class: "ant-col",
  style: {"flex":"1 1 auto"}
}
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_5 = { class: "ant-col ant-col-xs-12 ant-col-xl-2" }
const _hoisted_6 = { class: "ant-col ant-col-xs-12 ant-col-xl-2" }
const _hoisted_7 = { class: "ant-col ant-col-xs-12 ant-col-xl-2" }
const _hoisted_8 = { class: "ant-col ant-col-xs-12 ant-col-xl-2" }
const _hoisted_9 = { class: "ant-col ant-col-xs-12 ant-col-xl-2" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-1" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "ant-col ant-col-xs-24 ant-col-xl-1"
}
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_selecionar_servico = _resolveComponent("selecionar-servico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_icone = _resolveComponent("icone")!
  const _component_nota_fiscal_servico_item_modal = _resolveComponent("nota-fiscal-servico-item-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.props.editavel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_tooltip, {
              title: "Adicionar Novo Item",
              placement: "left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  class: "ant-btn ant-btn-secondary ss-acao-destaque-secundaria",
                  style: {"margin-top":"4px"},
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inserirItem && _ctx.inserirItem(...args)))
                }, "Inserir Serviço")
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.computedItens.length === 0)
      ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
          key: 1,
          mensagem: "Nenhum serviço adicionado."
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItens, (itemServico, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "ant-row"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_form_item, {
            label: "Serviço",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_servico, {
                empresas: [_ctx.props.empresa],
                codigoSelecionado: itemServico.codigoServico,
                "onUpdate:codigoSelecionado": ($event: any) => ((itemServico.codigoServico) = $event),
                onChange: ($event: any) => (_ctx.obterDadosServico(index)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["empresas", "codigoSelecionado", "onUpdate:codigoSelecionado", "onChange", "disabled"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_form_item, {
            label: "Valor do Serviço",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemServico.valorServico,
                "onUpdate:valor": ($event: any) => ((itemServico.valorServico) = $event),
                monetario: true,
                onBlur: ($event: any) => (_ctx.atualizarValores(index)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["valor", "onUpdate:valor", "onBlur", "disabled"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_form_item, {
            label: "ISS",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemServico.impostos.valorIss,
                monetario: true,
                disabled: true
              }, null, 8, ["valor"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_form_item, {
            label: "IR",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemServico.impostos.valorIr,
                monetario: true,
                disabled: true
              }, null, 8, ["valor"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_a_form_item, {
            label: "PIS",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemServico.impostos.valorPis,
                monetario: true,
                disabled: true
              }, null, 8, ["valor"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_a_form_item, {
            label: "COFINS",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemServico.impostos.valorCofins,
                monetario: true,
                disabled: true
              }, null, 8, ["valor"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_form_item, {
            label: "CSLL",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemServico.impostos.valorCsll,
                monetario: true,
                disabled: true
              }, null, 8, ["valor"])
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_a_form_item, {
            label: " ",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                class: "ant-btn ant-btn-secondary",
                style: {"width":"100%"},
                onClick: ($event: any) => (_ctx.abrirItem(index))
              }, [
                _createVNode(_component_icone, { nome: "visualizar" })
              ], 8, _hoisted_11)
            ]),
            _: 2
          }, 1024)
        ]),
        (_ctx.props.editavel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_a_form_item, {
                label: " ",
                class: "ss-margin-campos"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    class: "ant-btn ant-btn-secondary",
                    style: {"width":"100%"},
                    onClick: ($event: any) => (_ctx.excluirItem(index))
                  }, [
                    _createVNode(_component_icone, { nome: "excluir" })
                  ], 8, _hoisted_13)
                ]),
                _: 2
              }, 1024)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createVNode(_component_nota_fiscal_servico_item_modal, {
      empresa: _ctx.props.empresa,
      visivel: _ctx.state.exibirItem,
      "onUpdate:visivel": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.exibirItem) = $event)),
      item: _ctx.state.itemSelecionado
    }, null, 8, ["empresa", "visivel", "item"])
  ], 64))
}