import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_9 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_10 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_11 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    keyboard: false,
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    footer: null,
    width: 950,
    centered: "",
    "wrap-class-name": (_ctx.state.telaMobile ? 'full-modal' : '')
  }, {
    title: _withCtx(() => [
      _createTextVNode("Detalhes do Serviço")
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_skeleton, {
        loading: _ctx.telaBase.carregando
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, { layout: "vertical" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_form_item, {
                    label: "Atividade Econômica",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("input", {
                        class: "ant-input",
                        value: _ctx.props.item.descricaoAtividadeEconomica,
                        disabled: ""
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_a_form_item, {
                    label: "Local da Prestação",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("input", {
                        class: "ant-input",
                        value: _ctx.servicoServico.apresentacaoLocalPrestacaoServico(_ctx.props.item.localPrestacaoServico),
                        disabled: ""
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_form_item, {
                    label: "Tributação",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("input", {
                        class: "ant-input",
                        value: _ctx.servicoServico.apresentacaoIssRetido(_ctx.props.item.impostos.issRetido),
                        disabled: ""
                      }, null, 8, _hoisted_7)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_a_form_item, {
                    label: "% ISS",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_campo_numerico, {
                        valor: _ctx.props.item.impostos.aliquotaIss,
                        percentual: true,
                        disabled: true
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_a_form_item, {
                    label: "% IR",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_campo_numerico, {
                        valor: _ctx.props.item.impostos.aliquotaIr,
                        percentual: true,
                        disabled: true
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_a_form_item, {
                    label: "% PIS",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_campo_numerico, {
                        valor: _ctx.props.item.impostos.aliquotaPis,
                        percentual: true,
                        disabled: true
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_a_form_item, {
                    label: "% COFINS",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_campo_numerico, {
                        valor: _ctx.props.item.impostos.aliquotaCofins,
                        percentual: true,
                        disabled: true
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_a_form_item, {
                    label: "% CSLL",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_campo_numerico, {
                        valor: _ctx.props.item.impostos.aliquotaCsll,
                        percentual: true,
                        disabled: true
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["visible", "wrap-class-name"]))
}