
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EStatusRetornoBancario } from '@/models/Enumeradores/Financeiro/EStatusRetornoBancario';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import ServicoFaturamento from '@/servicos/Fiscal/ServicoFaturamento';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { IVenda, IVendaNotaFiscalServico } from '@/models/Entidades/Vendas/IVenda';
import SelecionarServico from '@/components/Cadastros/PrestacaoServicos/SelecionarServico.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { IDTOVendaServico } from '@/models/DTO/Vendas/IDTOVenda';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarCliente from '@/components/Cadastros/Pessoas/Clientes/SelecionarCliente.vue';

export default defineComponent({
  name: 'VendaUnificarServicosModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    venda: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    SelecionarServico,
    CampoNumerico,
    SelecionarCliente,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoVendas = new ServicoVendas();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    const servicoFaturamento = new ServicoFaturamento();
    servicoVendas.requisicaoSistema();
    servicoNotaFiscal.requisicaoSistema();
    servicoFaturamento.requisicaoSistema();

    const state = reactive({
      venda: {} as IVenda,
      servicos: [] as IVendaNotaFiscalServico[],
    });

    async function limparTela() {
      state.venda = {} as IVenda;
      state.servicos = [] as IVendaNotaFiscalServico[];
    }

    function alterarPrincipal(event: any, index: number) {
      state.servicos.forEach((s, i) => {
        if (index === i) {
          state.servicos[i].notaFiscalServico.servicos[0].principal = true;
        } else {
          state.servicos[i].notaFiscalServico.servicos[0].principal = false;
        }
      });
    }

    function verificarPrincipal() {
      let existePrincipal = false;

      state.servicos.forEach((s) => {
        if (s.notaFiscalServico.servicos[0].principal) {
          existePrincipal = true;
        }
      });

      if (!existePrincipal) {
        apresentarMensagemAlerta('Nenhum serviço foi definido como principal!');
      }
      return existePrincipal;
    }

    async function unificarServicos() {
      if (!verificarPrincipal()) return;

      const servicos = [] as IDTOVendaServico[];
      state.servicos.forEach((s) => {
        const servico = {} as IDTOVendaServico;
        servico.codigoVenda = s.codigoVenda;
        servico.codigoServico = s.notaFiscalServico.servicos[0].codigoServico;
        servico.codigoNotaFiscalServico = s.codigoNotaFiscalServico;
        servico.descricaoServico = s.notaFiscalServico.servicos[0].discriminacao;
        servico.valorServico = s.notaFiscalServico.servicos[0].valorServico;
        servico.valorInss = s.notaFiscalServico.servicos[0].impostos.valorInss;
        servico.valorCsll = s.notaFiscalServico.servicos[0].impostos.valorCsll;
        servico.valorIr = s.notaFiscalServico.servicos[0].impostos.valorIr;
        servico.valorPis = s.notaFiscalServico.servicos[0].impostos.valorPis;
        servico.valorCofins = s.notaFiscalServico.servicos[0].impostos.valorCofins;
        servico.principal = s.notaFiscalServico.servicos[0].principal;
        servicos.push(servico);
      });

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoVendas.unificarNFSes(props.empresa, servicos);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.venda = await servicoVendas.obter(props.venda);
        state.servicos = await servicoVendas.obterServicos(props.venda);
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      window,
      EStatusRetornoBancario,
      EStatusTituloFinanceiro,
      UtilitarioData,
      UtilitarioMascara,
      UtilitarioGeral,
      alterarPrincipal,
      unificarServicos,
      ECustomRenderRow,
    };
  },
});
