
import {
  computed, defineComponent,
} from 'vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { INotaFiscal } from '@/models/Entidades/Fiscal/INotaFiscal';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';

export default defineComponent({
  name: 'NotaFiscalFrete',
  props: {
    notaFiscal: {
      type: Object as () => INotaFiscal,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CampoNumerico,
  },
  emits: ['update:notaFiscal', 'totalAlterado', 'percentualDescontoAlterado', 'descontoAlterado'],
  setup(props, { emit }) {
    const computedNotaFiscal = computed({
      get: () => props.notaFiscal,
      set: (val: INotaFiscal) => {
        emit('update:notaFiscal', val);
      },
    });

    function totalAlterado() {
      emit('totalAlterado');
    }

    function descontoAlterado() {
      computedNotaFiscal.value.percentualDesconto = UtilitarioMatematico.calcularPorcentagem(computedNotaFiscal.value.totalProdutos, computedNotaFiscal.value.totalDesconto);
      emit('descontoAlterado');
    }

    function percentualDescontoAlterado() {
      computedNotaFiscal.value.totalDesconto = UtilitarioMatematico.calcularValorPercentual(computedNotaFiscal.value.totalProdutos, computedNotaFiscal.value.percentualDesconto);
      emit('percentualDescontoAlterado');
    }

    return {
      props,
      ETipoRelacaoPessoa,
      computedNotaFiscal,
      totalAlterado,
      descontoAlterado,
      percentualDescontoAlterado,
    };
  },
});
