
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { useGradeBase } from '@/core/composables/GradeBase';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IDTOVendaItem } from '@/models/DTO/Vendas/IDTOVenda';
import Card from '@/core/components/Tela/Card.vue';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';

export default defineComponent({
  name: 'BuscarItensVendas',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number || undefined,
    },
    codigoCliente: {
      type: Number,
      default: 0,
    },
    tituloTela: {
      type: String,
      default: 'Buscar Itens de Venda',
    },
    mostrarStatus: {
      type: Boolean,
      default: false,
    },
    ignorarDevolvidos: {
      type: Boolean,
      default: false,
    },
    filtros: {
      type: Object as () => IFiltroGenerico[],
      default: [] as IFiltroGenerico[],
    },
  },
  components: {
    Icone,
    Card,
    BuscaAvancada,
    MensagemSemDados,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, preencherEmpresasDisponiveis,
      filtrarPermissaoDadosUsuarioMultiEmpresas,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const {
      gradeBase, preencheOrdenacaoSelecionada, ordenacaoAtiva,
    } = useGradeBase();
    const servicoVendas = new ServicoVendas();
    servicoVendas.requisicaoSistema();

    const state = reactive({
      itens: [] as IDTOVendaItem[],
      buscaAvancada: {} as IBuscaAvancada,
      telaMobile: false,
    });

    function preencherColunas() {
      gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

      if (gradeBase.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Item', dataIndex: 'item', key: 'ItemVenda', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 2, visible: props.mostrarStatus, ordering: true, width: 100, align: 'center',
          },
          {
            title: 'Número', dataIndex: 'numeroVenda', key: 'Numero', position: 3, visible: true, ordering: true, width: 60,
          },
          {
            title: 'Dt de Criação', dataIndex: 'dataCriacao', key: 'DataCriacao', position: 5, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Cliente', dataIndex: 'nomeRazaoSocialDestinatario', key: 'NomeRazaoSocialDestinatario', position: 6, visible: true, ordering: true, width: 200,
          },
          {
            title: 'Produto', dataIndex: 'descricaoProduto', key: 'DescricaoProduto', position: 7, visible: true, ordering: true, width: 200,
          },
          {
            title: 'Unidade', dataIndex: 'descricaoUnidade', key: 'Unidade', position: 8, visible: true, width: 40, ordering: false, align: 'center', ellipsis: true,
          },
          {
            title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 9, visible: true, ordering: false, align: 'right', width: 50, ellipsis: true, customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'V. Unitário', dataIndex: 'precoUnitario', key: 'ValorUnitario', position: 10, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true, customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Total do Item', dataIndex: 'valorTotalItem', key: 'Total', position: 11, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true, customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Valor Total', dataIndex: 'totalNotaFiscal', key: 'totalNotaFiscal', position: 12, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 70,
          },
        ];
      }
    }

    function obterClasseStatus(status: string) {
      switch (status) {
        case 'Em digitação':
          return 'em-digitacao';
        case 'Aguardando conferência':
          return 'aguardando-conferencia';
        case 'Conferência em andamento':
          return 'conferencia-andamento';
        case 'Conferido':
          return 'conferido';
        case 'Concluído':
          return 'concluido';
        case 'Devolvido':
          return 'devolvido';
        default:
          return 'cancelado';
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    function adicionarFiltrosIniciais() {
      const filtroDataEmissao = {} as IFiltroGenericoAdicionado;
      const primeiroDia = UtilitarioData.definePrimeiroDiaMesNaDataDayJs(UtilitarioData.obterDataAtualDayJs());
      const ultimoDia = UtilitarioData.defineUltimoDiaMesNaDataDayJs(primeiroDia);
      state.buscaAvancada.chaveFiltrosAdicionados += 1;
      filtroDataEmissao.codigo = state.buscaAvancada.chaveFiltrosAdicionados;
      filtroDataEmissao.filtro = {} as IFiltroGenerico;
      filtroDataEmissao.filtro.identificador = 'DataVenda';
      filtroDataEmissao.apresentacao = 'Data da Venda';
      filtroDataEmissao.filtro.tipoDado = 7;
      filtroDataEmissao.filtro.operador = EOperadorLogico.E;
      filtroDataEmissao.filtro.apresentacao = 'Data da Venda';
      filtroDataEmissao.filtro.condicao = 10;
      filtroDataEmissao.filtro.valores = [UtilitarioData.aplicaFormatoData(primeiroDia), `${UtilitarioData.aplicaFormatoData(ultimoDia)} 23:59:59`];
      filtroDataEmissao.detalhes = `Entre: ${UtilitarioData.aplicaFormatoData(primeiroDia)} e ${UtilitarioData.aplicaFormatoData(ultimoDia)}`;
      state.buscaAvancada.filtrosAdicionados.push(filtroDataEmissao);
    }

    async function buscarItens() {
      state.itens = [];
      gradeBase.filtrosAplicados = [];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
      props.filtros.forEach((f) => {
        gradeBase.filtrosAplicados.push(f);
      });
      const filtroPessoa = {} as IFiltroGenerico;
      filtroPessoa.identificador = 'CodigoCliente';
      filtroPessoa.tipoDado = 2;
      filtroPessoa.operador = EOperadorLogico.E;
      filtroPessoa.condicao = 4;
      filtroPessoa.valores = [String(props.codigoCliente)];
      gradeBase.filtrosAplicados.push(filtroPessoa);

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = [props.empresa];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('DataCriacao|DESC', 'Numero');

      telaBase.carregando = true;
      let listaPaginada = {} as IListaPaginada;
      listaPaginada = await servicoVendas.buscaAvancadaItens(props.ignorarDevolvidos, parametrosConsulta, gradeBase.filtrosAplicados);
      state.itens = listaPaginada.dados;
      telaBase.carregando = false;
    }

    async function ordenarDados(key: string, sort: string) {
      await preencheOrdenacaoSelecionada(key, sort);
      await buscarItens();
    }

    function limparTela() {
      preencherColunas();
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      gradeBase.codigosSelecionados = [];
    }

    function confirmar() {
      const selecionados = state.itens.filter((i) => gradeBase.codigosSelecionados.includes(i.codigo));
      emit('confirmacao', selecionados);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([props.empresa]);
        telaBase.propriedadesConsulta = await servicoVendas.obterPropriedadesConsulta();
        if (telaBase.propriedadesConsulta.length > 0) {
          state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
          state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
        }
        adicionarFiltrosIniciais();
        buscarItens();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      ECustomRenderRow,
      window,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      buscarItens,
      mudarSelecao,
      ordenacaoAtiva,
      ordenarDados,
      confirmar,
      obterClasseStatus,
    };
  },
});
