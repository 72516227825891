import ApiERP from '@/core/conectores/ApiERP';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  IVenda, IVendaItem,
  IVendaNotaFiscalServico,
} from '@/models/Entidades/Vendas/IVenda';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { ETipoValorMovimentacao } from '@/models/Enumeradores/ETipoValorMovimentacao';
import {
  INotaFiscal,
  INotaFiscalIncidenciaCalculo,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import {
  IDTODadosCalculoTributacao, IDTODadosCalculoTributacaoIcms, IDTODadosCalculoTributacaoImpostoFederal, IDTODadosCalculoTributacaoIpi, IDTODadosCalculoTributacaoSubstituicaoTributaria,
} from '@/models/DTO/Tributacoes/IDTODadosCalculoTributacao';
import { IDTORetornoCalculoTributacao } from '@/models/DTO/Tributacoes/IDTORetornoCalculoTributacao';
import ServicoUtilitario from '../MeuSistema/ServicoUtilitario';
import { IParametrosConsultaVenda } from '@/models/ParametrosRequisicao/Vendas/IParametrosConsultaVenda';
import { IDTOVenda, IDTOVendaServico } from '@/models/DTO/Vendas/IDTOVenda';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IFormaPagamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IRetornoVenda } from '@/models/Retornos/Vendas/IRetornoVenda';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';

/**
 * Serviço de Vendas
 * Fornece todas regras de negócios e lógicas relacionado a painel de vendas.
 */
class ServicoVendas {
  endPoint = 'vendas';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(`${this.endPoint}`);
    return listaPropriedades;
  }

  public async buscaAvancada(processo: number, parametrosConsulta: IParametrosConsultaVenda, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    let parametrosAdicionais = '';
    if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
      parametrosConsulta.empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

      parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.valorBuscaRapida !== undefined) {
      if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
      }
    }

    if (filtros !== undefined) {
      const jsonFiltros = JSON.stringify(filtros);
      parametrosAdicionais += `&Filtros=${jsonFiltros}`;
    }
    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += `&Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigosSelecionados !== undefined) {
      parametrosConsulta.codigosSelecionados.forEach((codigo) => {
        parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/processos/${processo}${parametrosAdicionais}`);
    const listaPaginada = {} as IListaPaginada;
    listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
    listaPaginada.dados = result.data;
    return listaPaginada;
  }

  public async relatorioPadrao(processo: number, empresa: number, tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsultaVenda, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
    let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

      parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
    }
    if (codigosSelecionados !== undefined) {
      parametrosAdicionais += this.apiERP.obterParametroCodigosSelecionados(codigosSelecionados);
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/processos/${processo}/relatorio-padrao/${tipoArquivo}/empresa/${empresa}${parametrosEmpresas}${parametrosAdicionais}`);
    return result.data;
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IVenda): string {
    return String(objeto.codigo);
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async obterVendaConsultaRapida(venda: number, empresas?: number[]): Promise<IItemConsultaRapida> {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      valor: venda.toString(), filtrarPorCodigo: true, recursoAssociado: '',
    };

    if (UtilitarioGeral.validaLista(empresas)) {
      parametrosConsultaRapida.empresas = empresas;
    } else {
      parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
    }

    const listaVendas = await this.consultaRapida(parametrosConsultaRapida);
    if (UtilitarioGeral.validaLista(listaVendas)) {
      return listaVendas[0];
    }

    return {} as IItemConsultaRapida;
  }

  public async buscaAvancadaItens(ignorarDevolvdos: boolean, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
    const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);

    const result: any = await this.apiERP.get(`${this.endPoint}/itens/ignorar-devolvidos/${ignorarDevolvdos}${parametrosEmpresas}${parametrosAdicionais}`);
    const listaPaginada = {} as IListaPaginada;
    listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
    listaPaginada.dados = result.data;
    return listaPaginada;
  }

  public async obter(venda: number): Promise<IVenda> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${venda}`);
    return result.data;
  }

  public async obterItem(item: number): Promise<IVendaItem> {
    const result: any = await this.apiERP.get(`${this.endPoint}/itens/${item}`);
    return result.data;
  }

  public async obterItensCompletos(empresa: number, codigos: number[]): Promise<IVendaItem[]> {
    let parametrosAdicionais = '';
    for (let contador = 0; contador < codigos.length; contador += 1) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Codigos=${codigos[contador]}`;
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/itens/empresa/${empresa}${parametrosAdicionais}`);
    return result.data;
  }

  public async obterItens(venda: number): Promise<IVendaItem[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${venda}/itens`);
    return result.data;
  }

  public async obterServicos(venda: number): Promise<IVendaNotaFiscalServico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${venda}/servicos`);
    return result.data;
  }

  public async obterVendasServicos(codigos: number[]): Promise<IDTOVendaServico[]> {
    let parametrosCodigos = '';
    if (codigos !== undefined && codigos.length > 0) {
      codigos.forEach((codigo) => {
        parametrosCodigos += (parametrosCodigos !== '' ? '&' : '?');
        parametrosCodigos += `Codigos=${codigo}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/$vendas-servicos${parametrosCodigos}`);
    return result.data;
  }

  public async consultarMarcadores(marcardor: string): Promise<string[]> {
    let parametrosAdicionais = '';
    parametrosAdicionais += `?Marcador=${marcardor}`;

    const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
    return result.data;
  }

  public async validarAtualizacaoValores(venda: number, processo: number, empresa: number): Promise<boolean> {
    const result: any = await this.apiERP.get(`${this.endPoint}/valores-atualizados/${venda}/processo/${processo}/empresa/${empresa}`);
    return result.data;
  }

  public async imprimir(venda: number, processo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
    const result: any = await this.apiERP.get(`${this.endPoint}/impressao/${venda}/processo/${processo}/empresa/${empresa}/tipo-arquivo/${tipoArquivo}`);
    return result.data;
  }

  public async incluir(venda: IVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, venda);
    return result.data;
  }

  public async incluirCabecalho(venda: IVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/cabecalho`, venda);
    return result.data;
  }

  public async alterar(venda: IVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${venda.codigo}`, venda);
    return result.data;
  }

  public async atualizarOrdenacao(processo: number, empresa: number, ordenacaoRegistros: IOrdenacaoRegistro[]): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/processos/${processo}/ordenacao/empresa/${empresa}`, ordenacaoRegistros);
    return result.data;
  }

  public async concluirEtapa(venda: number): Promise<IRetornoVenda> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${venda}/etapas/concluir`, '');
    return result.data;
  }

  public async retornarEtapa(venda: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${venda}/etapas/retornar`, '');
    return result.data;
  }

  public async unificarNFSes(empresa: number, servicos: IDTOVendaServico[]): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/unificar-nfses/empresa/${empresa}`, servicos);
    return result.data;
  }

  public async alterarEtapaVenda(venda: number, etapa: number, motivoCancelamento: string): Promise<IRetornoVenda> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${venda}/etapas/${etapa}`, motivoCancelamento);
    return result.data;
  }

  public async excluir(venda: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${venda}`);
    return result.data;
  }

  public async imprimirVenda(venda: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${venda}/impressao/tipo-arquivo/${tipoArquivo}`);
    return result.data;
  }

  public estrategiaCriacaoVendaAutomatico(venda: IVenda): boolean {
    if (venda.codigo === 0 && venda.codigoVendedor > 0 && venda.codigoCliente > 0 && venda.codigoFormaPagamento > 0) {
      return true;
    }

    return false;
  }

  public obterDescricaoIncidencia(tipoValor: ETipoValorMovimentacao): string {
    switch (tipoValor) {
      case ETipoValorMovimentacao.Frete:
        return 'Frete';
      case ETipoValorMovimentacao.Seguro:
        return 'Seguro';
      case ETipoValorMovimentacao.Desconto:
        return 'Desconto';
      case ETipoValorMovimentacao.OutrasDespesas:
        return 'Outras Despesas';
      case ETipoValorMovimentacao.Icms:
        return 'ICMS';
      case ETipoValorMovimentacao.Ipi:
        return 'IPI';
      case ETipoValorMovimentacao.Pis:
        return 'PIS';
      case ETipoValorMovimentacao.Cofins:
        return 'COFINS';
      case ETipoValorMovimentacao.IcmsSt:
        return 'ICMS ST';
      case ETipoValorMovimentacao.Importacao:
        return 'I. de Importação';
      default:
        return 'Não identificado';
    }
  }

  public obterIdentificadorIncidencia(tipoValor: ETipoValorMovimentacao): string {
    switch (tipoValor) {
      case ETipoValorMovimentacao.Frete:
        return 'Frete';
      case ETipoValorMovimentacao.Seguro:
        return 'Seguro';
      case ETipoValorMovimentacao.Desconto:
        return 'Desconto';
      case ETipoValorMovimentacao.OutrasDespesas:
        return 'OutrasDespesas';
      case ETipoValorMovimentacao.Icms:
        return 'ICMS';
      case ETipoValorMovimentacao.Ipi:
        return 'IPI';
      case ETipoValorMovimentacao.Pis:
        return 'PIS';
      case ETipoValorMovimentacao.Cofins:
        return 'COFINS';
      case ETipoValorMovimentacao.IcmsSt:
        return 'ICMSST';
      case ETipoValorMovimentacao.Importacao:
        return 'II';
      default:
        return '';
    }
  }

  public verificaSePodeExcluir(status: EStatusVenda): boolean {
    switch (status) {
      case EStatusVenda.EmDigitacao:
      case EStatusVenda.Orcamento:
      case EStatusVenda.Efetivada:
      case EStatusVenda.EmFaturamento:
        return true;
      default:
        return false;
    }
  }

  public calcularTotalItem(item: IVendaItem): IVendaItem {
    const itemInterno = item;
    if (itemInterno.itemNotaFiscal.fatorConversao !== undefined) {
      // itemInterno.valorDescontoMaximo = UtilitarioMatematico.calcularValorPercentual(itemInterno.itemNotaFiscal.precoUnitario * itemInterno.itemNotaFiscal.quantidade, itemInterno.dadosPrecoProduto.descontoMaximo);
      itemInterno.precoTabela = itemInterno.itemNotaFiscal.precoUnitario;
      // itemInterno.precoTabela = itemInterno.dadosPrecoProduto.valor / itemInterno.itemNotaFiscal.fatorConversao;
      if (!UtilitarioGeral.validaNumerico(itemInterno.itemNotaFiscal.precoUnitario) || itemInterno.itemNotaFiscal.precoUnitario === 0) {
        itemInterno.itemNotaFiscal.precoUnitario = itemInterno.precoTabela;
      }
      itemInterno.itemNotaFiscal.valorTotal = parseFloat(((itemInterno.itemNotaFiscal.quantidade * itemInterno.itemNotaFiscal.precoUnitario) - itemInterno.itemNotaFiscal.valorDesconto).toFixed(2));
    }
    return itemInterno;
  }

  public async calcularTributacoes(item: IVendaItem, incidencias: INotaFiscalIncidenciaCalculo[]): Promise<IDTORetornoCalculoTributacao> {
    if (item.definicoesCenarioFiscal !== undefined) {
      const dadosCalculo: IDTODadosCalculoTributacao = {} as IDTODadosCalculoTributacao;
      dadosCalculo.codigoProdutoDefinicao = item.itemNotaFiscal.codigoProdutoDefinicao;
      dadosCalculo.quantidade = item.itemNotaFiscal.quantidade;
      dadosCalculo.precoUnitario = item.itemNotaFiscal.precoUnitario;
      dadosCalculo.valorTotalProduto = item.itemNotaFiscal.valorTotal;
      dadosCalculo.valorDesconto = item.itemNotaFiscal.valorDesconto;
      dadosCalculo.valorFrete = item.itemNotaFiscal.valorFrete;
      dadosCalculo.valorSeguro = item.itemNotaFiscal.valorSeguro;
      dadosCalculo.outrasDespesas = item.itemNotaFiscal.outrasDespesas;
      if (item.definicoesCenarioFiscal.encontrouDefinicoesIcms) {
        dadosCalculo.cst = item.definicoesCenarioFiscal.icms.cstIcms;
        dadosCalculo.incidencias = incidencias;
        dadosCalculo.icms = {} as IDTODadosCalculoTributacaoIcms;
        dadosCalculo.icms.aliquotaIcms = item.definicoesCenarioFiscal.icms.aliquotaIcms;
        dadosCalculo.icms.modalidadeBaseCalculoIcms = item.definicoesCenarioFiscal.icms.modalidadeBaseCalculoIcms;
        dadosCalculo.icms.reducaoBaseCalculoIcms = item.definicoesCenarioFiscal.icms.reducaoBaseCalculoIcms;

        if (item.definicoesCenarioFiscal.encontrouDefinicoesSubstituicaoTributaria) {
          dadosCalculo.substituicaoTributaria = {} as IDTODadosCalculoTributacaoSubstituicaoTributaria;
          dadosCalculo.substituicaoTributaria.aliquotaSubstituicaoTributaria = item.definicoesCenarioFiscal.icms.substituicao.aliquotaIcmsSubstituicao;
          dadosCalculo.substituicaoTributaria.modalidadeBaseCalculoSubstituicao = item.definicoesCenarioFiscal.icms.substituicao.modalidadeBaseCalculoIcmsSubstituicao;
          dadosCalculo.substituicaoTributaria.aliquotaIcmsInterno = item.definicoesCenarioFiscal.icms.substituicao.aliquotaIcmsInterno;
          dadosCalculo.substituicaoTributaria.reducaoBaseCalculoSubstituicao = item.definicoesCenarioFiscal.icms.substituicao.reducaoBaseCalculoIcmsSubstituicao;
          dadosCalculo.substituicaoTributaria.margemValorAdicionado = item.definicoesCenarioFiscal.icms.substituicao.margemValorAdicionado;
          dadosCalculo.substituicaoTributaria.pautaIcmsSubstituicao = item.definicoesCenarioFiscal.icms.substituicao.pautaIcmsSubstituicao;
        }
      }

      if (item.definicoesCenarioFiscal.encontrouDefinicoesIpi) {
        dadosCalculo.ipi = {} as IDTODadosCalculoTributacaoIpi;
        dadosCalculo.ipi.cst = item.definicoesCenarioFiscal.ipi.cstIpi;
        dadosCalculo.ipi.aliquota = item.definicoesCenarioFiscal.ipi.aliquotaIpi;
        dadosCalculo.ipi.tipoCalculo = item.definicoesCenarioFiscal.ipi.tipoCalculoIpi;
        dadosCalculo.ipi.valorUnidadeTributavel = item.definicoesCenarioFiscal.ipi.valorUnidadeTributavelIpi;
      }

      if (item.definicoesCenarioFiscal.encontrouDefinicoesPis) {
        dadosCalculo.pis = {} as IDTODadosCalculoTributacaoImpostoFederal;
        dadosCalculo.pis.cst = item.definicoesCenarioFiscal.pis.cstPis;
        dadosCalculo.pis.aliquota = item.definicoesCenarioFiscal.pis.aliquotaPis;
        dadosCalculo.pis.tipoCalculo = item.definicoesCenarioFiscal.pis.tipoCalculoPis;
        dadosCalculo.pis.reducaoBaseCalculo = item.definicoesCenarioFiscal.pis.reducaoBaseCalculoPis;
        dadosCalculo.pis.valorUnidadeTributavel = item.definicoesCenarioFiscal.pis.valorUnidadeTributavelPis;
      }

      if (item.definicoesCenarioFiscal.encontrouDefinicoesCofins) {
        dadosCalculo.cofins = {} as IDTODadosCalculoTributacaoImpostoFederal;
        dadosCalculo.cofins.cst = item.definicoesCenarioFiscal.cofins.cstCofins;
        dadosCalculo.cofins.aliquota = item.definicoesCenarioFiscal.cofins.aliquotaCofins;
        dadosCalculo.cofins.tipoCalculo = item.definicoesCenarioFiscal.cofins.tipoCalculoCofins;
        dadosCalculo.cofins.reducaoBaseCalculo = item.definicoesCenarioFiscal.cofins.reducaoBaseCalculoCofins;
        dadosCalculo.cofins.valorUnidadeTributavel = item.definicoesCenarioFiscal.cofins.valorUnidadeTributavelCofins;
      }
      const retornoCalculoTributacoes = await new ServicoUtilitario().calcularTributacoes(dadosCalculo);
      return retornoCalculoTributacoes;
    }
    return {} as IDTORetornoCalculoTributacao;
  }

  public obterDTO(venda: IVenda): IDTOVenda {
    const dto = {} as IDTOVenda;
    dto.codigo = venda.codigo;
    dto.codigoEmpresa = venda.codigoEmpresa;
    dto.codigoProcessoVendas = venda.codigoProcessoVendas;
    dto.codigoEtapaProcessoVendas = venda.codigoEtapaProcessoVendas;
    dto.codigoFormaPagamento = venda.codigoFormaPagamento;
    dto.codigoVendedor = venda.codigoVendedor;
    dto.codigoCliente = venda.codigoCliente;
    dto.nomeCliente = venda.notaFiscal.destinatario.nomeRazaoSocial;
    dto.numero = venda.numero;
    dto.dataCriacao = venda.dataCriacao;
    dto.dataVenda = venda.dataVenda;
    dto.status = venda.status;
    dto.totalVenda = venda.notaFiscal.totalNotaFiscal;
    dto.ordem = 0;
    return dto;
  }

  public obterIncidenciasNotaFiscalVendaPadrao(): INotaFiscalIncidenciaCalculo[] {
    const incidenciasCalculo:INotaFiscalIncidenciaCalculo[] = [
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'IPI', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Seguro', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'IPI', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Desconto', valor: true,
      },
    ];

    return incidenciasCalculo;
  }

  private async obterDescontoCliente(codigo: number): Promise<number> {
    const result: any = await this.apiERP.get(`clientes/desconto/${codigo}`);
    return result.data;
  }

  private async validarDesconto(notaFiscal: INotaFiscal, formaPagamento: IFormaPagamento): Promise<IRetornoRequisicao> {
    const retorno = {} as IRetornoRequisicao;
    retorno.status = EStatusRetornoRequisicao.Sucesso;
    retorno.mensagem = '';
    let tipoUnico = true;
    let descontoMaximo = 0;
    let descontoTipoDocumento = 0;
    const descontoCliente = await this.obterDescontoCliente(notaFiscal.destinatario.codigoPessoa);

    if (notaFiscal.detalhesPagamento.length > 0) {
      for (let i = 0; i < notaFiscal.detalhesPagamento.length; i += 1) {
        if (i > 0) {
          if (notaFiscal.detalhesPagamento[i].codigoTipoDocumentoFinanceiro !== notaFiscal.detalhesPagamento[i - 1].codigoTipoDocumentoFinanceiro) {
            tipoUnico = false;
          }
        }
      }
      if (tipoUnico) {
        const indexTipoPagamento = formaPagamento.tiposDocumentosFinanceiro.findIndex((t) => t.codigoTipoDocumentoFinanceiro === notaFiscal.detalhesPagamento[0].codigoTipoDocumentoFinanceiro);
        descontoTipoDocumento = formaPagamento.tiposDocumentosFinanceiro[indexTipoPagamento].desconto;
      }
    }
    if (UtilitarioMatematico.arredondarValor(descontoCliente, 2) > UtilitarioMatematico.arredondarValor(descontoTipoDocumento, 2)) {
      descontoMaximo = descontoCliente;
    } else {
      descontoMaximo = descontoTipoDocumento;
    }
    if (descontoMaximo > 0) {
      const descontoLancado = UtilitarioMatematico.calcularPorcentagem(notaFiscal.totalNotaFiscal, notaFiscal.totalDesconto);
      if (UtilitarioMatematico.arredondarValor(descontoLancado, 2) > UtilitarioMatematico.arredondarValor(descontoMaximo, 2)) {
        retorno.status = EStatusRetornoRequisicao.Erro;
        retorno.mensagem = `O desconto lançado na venda é maior que o percentual máximo de ${UtilitarioMascara.mascararValor(descontoMaximo, 2)}%!`;
      }
    }

    return retorno;
  }

  public async validarFormaPagamento(notaFiscal: INotaFiscal, formaPagamento: IFormaPagamento): Promise<IRetornoRequisicao> {
    let retorno = {} as IRetornoRequisicao;

    retorno = await this.validarDesconto(notaFiscal, formaPagamento);
    if (retorno.status !== EStatusRetornoRequisicao.Sucesso) return retorno;

    if (notaFiscal.detalhesPagamento.length === 0) {
      return retorno;
    }

    formaPagamento.tiposDocumentosFinanceiro.forEach((t) => {
      if (t.valorMaximo > 0 || t.valorMinimo > 0 || t.valorMinParcela > 0) {
        let qtdParcelas = 0;
        let valorParcelado = 0;

        notaFiscal.detalhesPagamento.forEach((d) => {
          if (d.codigoTipoDocumentoFinanceiro === t.codigoTipoDocumentoFinanceiro) {
            if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
              qtdParcelas += 1;
              valorParcelado += d.valorPagamento;

              if (UtilitarioMatematico.arredondarValor(d.valorPagamento, 2) < UtilitarioMatematico.arredondarValor(t.valorMinParcela, 2) && t.valorMinParcela > 0) {
                retorno.status = EStatusRetornoRequisicao.Erro;
                retorno.mensagem = `O detalhe de pagamento ${d.numeroTitulo} está com o valor menor que a parcela mínima de ${UtilitarioMascara.mascararValor(t.valorMinParcela, 2, true)} definido para ${t.descricaoTipoDocumentoFinanceiro}`;
              }
            }
          }
        });

        if (t.parcelamentos.length > 0) {
          if (qtdParcelas > t.parcelamentos.length && retorno.status === EStatusRetornoRequisicao.Sucesso) {
            retorno.status = EStatusRetornoRequisicao.Erro;
            retorno.mensagem = `Foram lançadas mais parcelas que o permitido para ${t.descricaoTipoDocumentoFinanceiro}!`;
          }

          if (qtdParcelas > 0 && retorno.status === EStatusRetornoRequisicao.Sucesso) {
            const indexParcelamento = t.parcelamentos.findIndex((p) => p.qtdParcelas === qtdParcelas);
            notaFiscal.detalhesPagamento.forEach((d) => {
              const prazo = UtilitarioData.verificaDiferencaDiasEntreDatas(notaFiscal.dataEntradaSaida, d.data);
              if (prazo > t.parcelamentos[indexParcelamento].prazoTotal) {
                retorno.status = EStatusRetornoRequisicao.Erro;
                retorno.mensagem = `O detalhe de pagamento ${d.numeroTitulo} está com o prazo maior que o de ${t.parcelamentos[indexParcelamento].prazoTotal} dias definido para ${qtdParcelas} parcelas de ${t.descricaoTipoDocumentoFinanceiro}!`;
              }
            });
          }

          if (qtdParcelas > 0 && retorno.status === EStatusRetornoRequisicao.Sucesso) {
            if (UtilitarioMatematico.arredondarValor(valorParcelado, 2) > UtilitarioMatematico.arredondarValor(t.valorMaximo, 2) && t.valorMaximo > 0) {
              retorno.status = EStatusRetornoRequisicao.Erro;
              retorno.mensagem = `O valor total lançado utilizando ${t.descricaoTipoDocumentoFinanceiro} é maior que o ${UtilitarioMascara.mascararValor(t.valorMaximo, 2, true)} permitido!`;
            }

            if (UtilitarioMatematico.arredondarValor(valorParcelado, 2) < UtilitarioMatematico.arredondarValor(t.valorMinimo, 2) && t.valorMinimo > 0) {
              retorno.status = EStatusRetornoRequisicao.Erro;
              retorno.mensagem = `O valor total lançado utilizando ${t.descricaoTipoDocumentoFinanceiro} é menor que o ${UtilitarioMascara.mascararValor(t.valorMaximo, 2, true)} permitido!`;
            }
          }
        }
      }
    });

    return retorno;
  }
}
export default ServicoVendas;
