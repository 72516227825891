
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { INotaFiscalServicoItem } from '@/models/Entidades/Fiscal/INotaFiscalServico';
import ServicoServico from '@/servicos/Cadastros/PrestacaoServicos/ServicoServico';

export default defineComponent({
  name: 'NotaFiscalServicoItemModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    item: {
      type: Object as () => INotaFiscalServicoItem,
      required: true,
    },
  },
  components: {
    Icone,
    CampoNumerico,
  },
  emits: ['update:visivel', 'salvar'],
  setup(props, { emit }) {
    const servicoServico = new ServicoServico();
    servicoServico.requisicaoSistema();
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      telaMobile: false,
    });

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      telaBase.carregando = true;
      // if (modalBase.computedVisivel) {
      // }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      servicoServico,
    };
  },
});
