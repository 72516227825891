
import { defineComponent, computed, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { INotaFiscalServicoItem } from '@/models/Entidades/Fiscal/INotaFiscalServico';
import ServicoServico from '@/servicos/Cadastros/PrestacaoServicos/ServicoServico';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { EExigibilidadeIss } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EExigibilidadeIss';
import SelecionarServico from '@/components/Cadastros/PrestacaoServicos/SelecionarServico.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import NotaFiscalServicoItemModal from './NotaFiscalServicoItemModal.vue';

export default defineComponent({
  name: 'NotaFiscalServicoListagem',
  components: {
    SelecionarServico,
    CampoNumerico,
    Icone,
    MensagemSemDados,
    NotaFiscalServicoItemModal,
  },
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    regimeTributario: {
      type: Number,
      required: true,
    },
    obrigarPrincipal: {
      type: Boolean,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    itens: {
      type: Array as () => INotaFiscalServicoItem[],
      required: true,
    },
  },
  emits: ['update:itens', 'excluirItem', 'atualizarValores', 'atualizarLocalPrestacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const servicoServico = new ServicoServico();
    const servicoCalculosComerciais = new ServicoCalculosComerciais();

    const state = reactive({
      exibirItem: false,
      itemSelecionado: {} as INotaFiscalServicoItem,
    });

    const computedItens = computed({
      get: () => props.itens,
      set: (valor: INotaFiscalServicoItem[]) => {
        emit('update:itens', valor);
      },
    });

    function inserirItem() {
      const servico = servicoServico.instanciarItemNotaFiscalServico();
      computedItens.value.push(servico);
    }

    function excluirItem(index: number) {
      const codigo = computedItens.value[index].codigo;
      computedItens.value.splice(index, 1);
      if (computedItens.value.length === 1) {
        computedItens.value[0].principal = true;
      }
      if (codigo > 0) emit('excluirItem', codigo);
    }

    function abrirItem(index: number) {
      state.exibirItem = true;
      state.itemSelecionado = computedItens.value[index];
    }

    async function atualizarValores(index: number) {
      computedItens.value[index].valorTotal = (computedItens.value[index].valorServico - computedItens.value[index].impostos.descontoIncondicionado - computedItens.value[index].impostos.descontoCondicionado);
      const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoesServico(computedItens.value[index], props.regimeTributario);
      if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
        computedItens.value[index].impostos.baseCalculo = retornoCalculoTributacoes.baseCalculo;
        computedItens.value[index].impostos.valorIss = retornoCalculoTributacoes.valorIss;
        computedItens.value[index].impostos.valorIssRetido = retornoCalculoTributacoes.valorIssRetido;
        computedItens.value[index].impostos.valorPis = retornoCalculoTributacoes.valorPis;
        computedItens.value[index].impostos.valorCofins = retornoCalculoTributacoes.valorCofins;
        computedItens.value[index].impostos.valorIr = retornoCalculoTributacoes.valorIr;
        computedItens.value[index].impostos.valorInss = retornoCalculoTributacoes.valorInss;
        computedItens.value[index].impostos.valorCsll = retornoCalculoTributacoes.valorCsll;
        computedItens.value[index].impostos.valorAproximadoTributos = retornoCalculoTributacoes.valorAproximadoTributos;
      }
      emit('atualizarValores');
    }

    function alterarPrincipal(event: any, index: number) {
      computedItens.value.forEach((s, i) => {
        if (index === i) {
          if (props.obrigarPrincipal) {
            computedItens.value[i].principal = true;
          } else {
            computedItens.value[i].principal = event.target.checked;
          }
          if (event.target.checked) {
            emit('atualizarLocalPrestacao', computedItens.value[i].localPrestacaoServico);
          }
        } else {
          computedItens.value[i].principal = false;
        }
      });

      if (computedItens.value.filter((i) => i.principal).length > 0) {
        computedItens.value.forEach((s, i) => {
          if (!s.principal) {
            computedItens.value[i].valorServico = 0;
          }
        });
        emit('atualizarValores');
      }
    }

    async function obterDadosServico(index: number) {
      const servico = await servicoServico.obter(computedItens.value[index].codigoServico, props.empresa);
      if (UtilitarioGeral.objetoValido(servico)) {
        computedItens.value[index].discriminacao = servico.descricao;
        computedItens.value[index].localPrestacaoServico = servico.localPrestacaoServico;
        computedItens.value[index].exigibilidadeIss = EExigibilidadeIss.Exigivel;
        computedItens.value[index].codigoAtividadeEconomica = servico.codigoAtividadeEconomica;
        computedItens.value[index].descricaoAtividadeEconomica = servico.descricaoAtividadeEconomica;
        if (computedItens.value.filter((i) => i.principal).length > 0 && computedItens.value[index].principal) {
          computedItens.value[index].valorServico = servico.valorPadrao;
        } else {
          computedItens.value[index].valorServico = 0;
        }
        computedItens.value[index].impostos.aliquotaIss = servico.aliquotaIss;
        computedItens.value[index].impostos.valorIss = 0;
        computedItens.value[index].impostos.issRetido = servico.issRetido;
        computedItens.value[index].impostos.valorIssRetido = 0;
        computedItens.value[index].impostos.aliquotaPis = servico.aliquotaPis;
        computedItens.value[index].impostos.valorPis = 0;
        computedItens.value[index].impostos.aliquotaCofins = servico.aliquotaCofins;
        computedItens.value[index].impostos.valorCofins = 0;
        computedItens.value[index].impostos.aliquotaInss = servico.aliquotaInss;
        computedItens.value[index].impostos.valorInss = 0;
        computedItens.value[index].impostos.aliquotaIr = servico.aliquotaIr;
        computedItens.value[index].impostos.valorIr = 0;
        computedItens.value[index].impostos.aliquotaCsll = servico.aliquotaCsll;
        computedItens.value[index].impostos.valorCsll = 0;
        computedItens.value[index].impostos.valorDeducoes = 0;
        computedItens.value[index].impostos.valorOutrasRetencoes = 0;
        computedItens.value[index].impostos.descontoCondicionado = 0;
        computedItens.value[index].impostos.descontoIncondicionado = 0;
        computedItens.value[index].impostos.valorAproximadoTributos = 0;
        atualizarValores(index);
      } else {
        computedItens.value[index].codigo = 0;
        apresentarMensagemAlerta('Não foi possível obter as informações do serviço.');
      }
    }

    return {
      state,
      props,
      telaBase,
      computedItens,
      servicoServico,
      inserirItem,
      excluirItem,
      abrirItem,
      alterarPrincipal,
      obterDadosServico,
      atualizarValores,
    };
  },
});
